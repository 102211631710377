/* 
* @Author: liuzhixiang  
* @Date: 2021-08-07 09:46:10  
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-We 04:12:03
*/
<template>
  <div class="width_1200 bgc_ffffff">
    <div class="details">
      <div class="title">
        {{ details.Title }}
      </div>
      <div class="time">
        <span>作者：{{ details.ArtistName }}</span>
        <span>时间：{{ details.CreateTime | dataYMD }}</span>
      </div>
      <div class="content" v-html="details.ProduceIntroduce"></div>
    </div>
    <el-image
      id="preview"
      v-show="false"
      :src="srcList[0]"
      :preview-src-list="srcList"
    >
    </el-image>
  </div>
</template>

<script>
import $ from "jquery";
import { queryArtistProduceDetail } from "@/api/fineArts";
import noData from "@/components/noData";
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      details: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      srcList: []
    };
  },
  components: {
    noData,
  },
  created () {
    this.route = this.$route.query;
  },
  mounted () {
    this.init();
    this.$nextTick(() => {
      $(".content").on("click", "img", function () {
        document.getElementById("preview").click()
      })
    })
  },
  methods: {
    history () {
      this.$router.go(-1)
    },
    //  
    async init () {
      let parm = "?produceId=" + this.route.id;
      const res = await queryArtistProduceDetail(parm);
      if (res.success === true) {
        this.details = res.response;
        this.srcList = this.common.getImgSrc(res.response.ProduceIntroduce)
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  margin: 30px 0 74px;
  padding: 20px;
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
  .time {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin: 10px 0 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
    span + span {
      margin-left: 20px;
    }
  }
  .content {
    line-height: 1.8;
    padding: 15px 0;
    font-size: 13px;
    color: #666666;
    /deep/img {
      width: 100%;
      margin: 5px 0;
    }
  }
  .his {
    position: fixed;
    bottom: 30vh;
    right: 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #168af4;
    background-color: #fff;
    box-shadow: 0 1px 5px 1px rgb(26 26 26 / 10%);
  }
}
</style>